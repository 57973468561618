import React, { useState } from 'react'

// MATERIAL
import { Collapse, Typography, Box, Container } from '@material-ui/core'

//CUSTOM COMPONENTS
import GreyBox from '../Shared/GreyBox'
import ContactForm from './ContactForm'

export default function QuieroSerCliente() {
  const [submitted, setSubmitted] = useState(false)

  return (
    <GreyBox py={{ xs: 10, sm: 20 }} id="contact-footer">
      <Container maxWidth="md" style={{ height: '100%' }}>
        <Box display="flex" flexDirection="column" height={1}>
          <Collapse
            in={submitted}
            timeout={{ enter: 400 }}
            mountOnEnter
            unmountOnExit
          >
            <Box mb={2}>
              <Typography
                variant="h2"
                color="primary"
                style={{
                  fontWeight: 700
                }}
                align="center"
                gutterBottom
              >
                ¡Ya vas a ser cliente!
              </Typography>
              <Typography variant="subtitle1" align="center" gutterBottom>
                Ya envíamos tu formulario y una confirmación a tu mail. Gracias!
              </Typography>
            </Box>
          </Collapse>
          <Collapse
            timeout={{ exit: 500 }}
            in={!submitted}
            mountOnEnter
            unmountOnExit
          >
            <Box mb={2}>
              <Typography
                variant="h2"
                color="primary"
                style={{
                  fontWeight: 700
                }}
                align="center"
                gutterBottom
              >
                ¡Quiero ser cliente!
              </Typography>
              <Typography variant="subtitle1" align="center" gutterBottom>
                ¿Estas buscando una logística que pueda ofrecer algo diferente?
                ¿Querés conocer más sobre nuestros servicios? <br /> Completá el
                formulario de abajo y un ejecutivo de cuentas se comunicará con
                vos
              </Typography>
            </Box>
            <Box flexGrow={1} />
            <ContactForm setSubmitted={setSubmitted} />
          </Collapse>
        </Box>
      </Container>
    </GreyBox>
  )
}
