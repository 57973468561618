import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  greyBox: {
    backgroundColor: theme.palette.grey[50],
  },
}))

export default function GreyBox({ children, className, ...otherProps }) {
  const { greyBox } = useStyles()
  return (
    <Box
      className={className ? `${className} ${greyBox}` : greyBox}
      {...otherProps}
    >
      {children}
    </Box>
  )
}
