import React, { Component } from 'react'
import styled from 'styled-components'

const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    & svg{
        fill: black !important;
        max-height: 50%;
        width: 70%;
        opacity: 0;
        transition: opacity 2s ease-in-out; 
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    & div{
        height: 50%;
        width: 100%;
        opacity: 0;
        transition: opacity 2s ease-in-out;
        position: absolute;
        margin-left: 0;
        margin-right: 0;
        left: 0;
        right: 0;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    & svg:nth-child(${props => props.active +1}){
        opacity: 1;
    }
    & div:nth-child(${props => props.active +1}){
        opacity: 1;
    }
`


export default class ClientsSlides extends Component {
    state = {
        active: 0
    }

    componentDidMount(){
        this.slides = setInterval(() => {
            if(this.state.active === this.props.children.length -1){
                this.setState({active: 0})
            } else{
                this.setState({active: this.state.active + 1})
            }
        }, this.props.delay * 1000 || 5000)
    }

    componentWillUnmount(){
        clearInterval(this.slides)
    }

    render() {
        return (
            <MainContainer active={this.state.active}>
                {this.props.children}
            </MainContainer>
        )
    }
}

