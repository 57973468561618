import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import liveChatScript from './livechatScript'

export default props => {
    const {pathname} = useLocation()
    liveChatScript()
    useMemo(() => {
        if(pathname.includes('/mapeo')){
            window.LiveChatWidget.call('destroy')
        } else {
            window.LiveChatWidget.init()
            window.LiveChatWidget.call('minimize')
            // window.LiveChatWidget.call('set_customer_name', "this is a test")
            // window.LiveChatWidget.call('set_customer_email', "test@gmail.com")
            // window.LiveChatWidget.call('set_session_variables', {
            //     test: "this is a test"
            // })
        }
    }, [pathname])

    return (
        <>
            <noscript>
                <a href="https://www.livechatinc.com/chat-with/12300234/" rel="nofollow">Chat with us, powered by </a>
                <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow">LiveChat</a>
            </noscript>
        </>
    )
}