
import React from 'react';
import ReactDOM from 'react-dom';

import Main from './Routes/index';
import registerServiceWorker from './registerServiceWorker';

// ReactDOM.render(<Search />, document.getElementById('root'));
ReactDOM.render(<Main />, document.getElementById('root'));
registerServiceWorker();
