import React from 'react'
import { Formik, Form, Field } from 'formik'
import { Fade, Box, Button, LinearProgress, Grid } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'

// CONEXION
import { mutate, CONTACTO } from './mutations'

// HOOKS
import useError from '../../../Hooks/useError'

const contactSchema = yup.object().shape({
  email: yup.string().email('Mail inválido').required('Es requerido'),
  nombreApellido: yup
    .string()
    .min(3, 'Muy corto')
    .max(55, 'Muy largo')
    .required('Es requerido'),
  tel: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Debe ser un teléfono'
    )
    .required('Es requerido'),
  cantidadEnvios: yup
    .number()
    .min(1, 'Debe ser mayor que 0')
    .required('Es requerido'),
  empresa: yup.string().min(2, 'Muy corto').required('Es requerido')
})

export default function ContactForm({ setSubmitted }) {
  const errorHandler = useError()

  const formSubmit = (
    { nombreApellido, email, tel, cantidadEnvios, empresa },
    { setSubmitting }
  ) => {
    const cuerpo = `
      <b>Nombre: </b> ${nombreApellido}<br>
      <b>Empresa: </b> ${empresa}<br>
      <b>Mail: </b> ${email}<br>
      <b>Teléfono: </b> ${tel}<br>
      <b>Cantidad Envíos mensual: </b> ${cantidadEnvios}<br>
    `

    Promise.all([
      mutate(CONTACTO, {
        destinatarios: 'info@clicpaq.com',
        asunto: `Nuevo formulario de contacto - ${nombreApellido}`,
        titulo: 'Datos de contacto',
        cuerpo
      }),
      mutate(CONTACTO, {
        destinatarios: email,
        asunto: 'Formulario de contacto recibido',
        titulo: 'Gracias por contactarse',
        cuerpo: 'En breve recibirás el contacto de un ejecutivo de cuentas.'
      })
    ])
      .then(() => {
        setSubmitted(true)
      })
      .catch(errorHandler)
      .finally(() => setSubmitting(false))
  }

  return (
    <Formik
      initialValues={{
        email: '',
        nombreApellido: '',
        tel: '',
        cantidadEnvios: '',
        empresa: ''
      }}
      validationSchema={contactSchema}
      onSubmit={formSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form
          onSubmit={e => {
            e.preventDefault()
            submitForm()
          }}
        >
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={6} md={4}>
              <Field
                component={TextField}
                name="email"
                type="text"
                label="Email"
                variant="outlined"
                color="primary"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Field
                component={TextField}
                name="nombreApellido"
                type="text"
                label="Nombre y Apellido"
                variant="outlined"
                color="primary"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                component={TextField}
                name="tel"
                type="text"
                label="Teléfono"
                variant="outlined"
                color="primary"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field
                component={TextField}
                name="cantidadEnvios"
                type="number"
                label="Cantidad envíos mensual"
                variant="outlined"
                color="primary"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field
                component={TextField}
                name="empresa"
                type="text"
                label="Nombre de empresa"
                variant="outlined"
                color="primary"
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box width={250}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                >
                  Enviar
                </Button>
                <Fade in={isSubmitting}>
                  <Box my={1}>
                    <LinearProgress />
                  </Box>
                </Fade>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
