import React, {useRef} from 'react'

// MATERIAL
import {
  AppBar,
  Toolbar,
  Box,
  Divider,
  InputBase,
  Button
} from '@material-ui/core'

import {
  Search as SearchIcon,
  ExitToApp as LoginIcon
} from '@material-ui/icons'
import { fade, makeStyles } from '@material-ui/core/styles'

import {ReactComponent as Logo} from '../../resources/images/logo.svg'

// HOOKS
import useLinkTo from '../../Hooks/useLinkTo'
import useError from '../../Hooks/useError'


const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.10),
    },
    padding: `4px 0`,
    marginLeft: 0,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    top: 0,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


export default () => {
    const linkTo = useLinkTo()
    const inputRef = useRef()
    const classes = useStyles()
    const errorHandler = useError()

    const searchGuia = e => {
        e.preventDefault()
        const value = parseInt(inputRef.current.value)
        if(isNaN(value)) {
          errorHandler({message: "El número envío no es válido"})
        }else{
          linkTo(`/seguimiento/${value}`)
        }
    }

    return (
        <AppBar id="appbar" color="transparent" style={{backgroundColor: "white"}}>   
            <Toolbar>
              <a href="/" style={{position: "relative"}}>
                <span 
                  style={{
                    position: "absolute",
                    color: "transparent",
                    pointerEvents: "none"
                  }}
                >Logo Clicpaq</span>
                <Logo height={40}/>
              </a>
              <Box flexGrow={1} />
              <Box 
                component="form"
                display={{xs: "none", sm:"block"}} 
                className={classes.search}
                onSubmit={searchGuia}
              >
                <div className={classes.searchIcon}>
                  <SearchIcon color="primary"/>
                </div>
                <InputBase
                  placeholder="Buscar envío"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'buscar' }}
                  inputRef={inputRef}
                />
              </Box>
              <Box display={{xs: "none", sm: "block"}} mx={2} height={24}>
                  <Divider orientation="vertical"/>
              </Box>
              <Button 
                  color="primary" 
                  size="large" 
                  href={localStorage.getItem('token') ? '/app' : '/login'}
                  startIcon={<LoginIcon />}
              >
                LOGIN
              </Button>
            </Toolbar>
        </AppBar>
    ) 
}