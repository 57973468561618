import color from 'color'
const theme = {
    //COLORES
    primary: '#8F1E2E',
    secondary: '#6A111E',
    tertiary: '#47030D',
    black: '#0D0D0D',
    grey: '#737373',
    lightGrey: '#EEEEEE',
    white: '#FFF',
    success: '#5CB85C',
    warning: '#F0AD4E',
    error: '#D9534F',
    greyHover: color("#737373").alpha(0.2).string(),
    greyFocus: color("#737373").alpha(0.3).string(),
    tableGrey: color("#EEEEEE").darken(0.15).string(),
    //FUENTES
    primaryFont: "fira-sans",
    secondaryFont: "proxima-nova",
    //TAMAÑOS
    fontSize: 1,
    borderRadius: "10px",
    borderRadiusSmall: "3px",
    shadow100: "0px 16px 24px 0px rgba(48, 49, 41, 0.1)",
    bounceBezier: "cubic-bezier(.24,1.48,.82,.84)",
    bp: {
        t: "@media(max-width: 991px)",
        ml: "@media(max-width: 767px)",
        mp: " @media(max-width: 479px)"
    }
}

export default theme;