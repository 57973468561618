import {useContext} from 'react'
import AlertContext from '../Contexts/AlertContext'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

export default () => {
    const {setOpen, setMessage, setSeverity} = useContext(AlertContext)
    const {pathname} = useLocation()
    return useCallback((error) => {
        if(error.type === "UNAUTHENTICATED"){
            setTimeout(() => {
                window.localStorage.removeItem("token")
                window.location = `/login?redirect=${pathname}`
            }, 1000)
        }
        setOpen(true)
        setMessage(error.message)
        setSeverity('error')
    }, [setOpen, setMessage, setSeverity, pathname])
}