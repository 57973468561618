import mutate from '../../../Client/mutate'
import gql from 'graphql-tag'

export { mutate }
export const CONTACTO = gql`
  mutation formularioContacto(
    $destinatarios: String!
    $asunto: String!
    $titulo: String!
    $cuerpo: String!
  ) {
    enviarMail(
      destinatarios: $destinatarios
      asunto: $asunto
      titulo: $titulo
      cuerpo: $cuerpo
    )
  }
`
