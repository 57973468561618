import React from 'react'

// RESOURCES
import { ReactComponent as Logo } from '../../resources/images/logo.svg'
import theme from '../../shared/oldTheme'

// MATERIAL
import { Typography, Box, Container, Button, Divider } from '@material-ui/core'

// HOOKS
import useLinkTo from '../../Hooks/useLinkTo'

export default ({ scrollTo }) => {
  const linkTo = useLinkTo()

  const accesoClick = e => {
    e.preventDefault()

    linkTo(localStorage.getItem('token') ? '/app' : '/login')
  }
  const homeClick = () => {
    if (scrollTo) {
      scrollTo(0)
    } else {
      linkTo('/')
    }
  }

  return (
    <>
      <Box py={5} display="flex" flexDirection="column" alignItems="center">
        <Logo height="40px" />
      </Box>
      <Box py={2} width={1} style={{ backgroundColor: theme.lightGrey }}>
        <Container maxWidth="md">
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box mx={2}>
              <Button
                onClick={accesoClick}
                href={localStorage.getItem('token') ? '/app' : '/login'}
                size="large"
              >
                Acceso clientes
              </Button>
            </Box>
            <Box mx={2}>
              <Button
                onClick={scrollTo ? homeClick : null}
                href={scrollTo ? undefined : '/'}
                size="large"
              >
                Home
              </Button>
            </Box>
            {/* <Box mx={2}>
              <Button
                onClick={scrollTo ? homeClick : null}
                href={scrollTo ? undefined : '/'}
                size="large"
              >
                Calidad
              </Button>
            </Box> */}
          </Box>
          <Box my={2}>
            <Divider variant="middle" light={true} />
          </Box>
          <Typography display="block" variant="caption" align="center">
            Copyright © {new Date().getFullYear()} Clicpaq SA. Todos los
            derechos reservados.
          </Typography>
        </Container>
      </Box>
    </>
  )
}
