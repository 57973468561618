const images = [
    "Mockup-Macbook",
    "Mockup-iPad",
    "Mockup-Iphone",
    "muestra-busqueda",
    "calendar-bg",
    "floating-calendar",
    "contenido-bg",
    "floating-contenido",
    "floating-remito",
    "remito-bg",
    "notification-bg",
    "long-line",
    "digitalizar-table",
    "digitalizar-doc",
    "digitalizar-fecha",
    "digitalizar-numero",
    "digitalizar-movimientos",
    "bolsa1-digitaliza",
    "bolsa2-digitaliza",
    "digitalizar-caja-der",
    "digitalizar-caja-izq",
    "digitalizar-compartir",
    "digitalizar-compu",
    "digitalizar-estanteria-der",
    "digitalizar-estanteria-izq",
    "digitalizar-scanner",
    "long-line-digitaliza"
  ]
  
  const srcImg = images.map(image => ({
    png: {
      "x1": require(`../../resources/images/${image}.png`),
      "x2": require(`../../resources/images/${image}@2x.png`),
    },
    webp:{
      "x1": require(`../../resources/images/webp/${image}.webp`),
      "x2": require(`../../resources/images/webp/${image}@2x.webp`),
    }
  }))
  
  const getImgIndex = image => images.findIndex(img => img === image)
  
  export const getImg = (image, ext) => srcImg[getImgIndex(image)][ext].x1
  
  export const getImgSet = (image, ext) => {
    const img = srcImg[getImgIndex(image)][ext]
    return `${img.x1}, ${img.x2} 2x`
  }