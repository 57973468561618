import React, { useRef } from 'react'
import {
  Box,
  Container,
  Typography,
  TextField,
  IconButton,
  Paper,
} from '@material-ui/core'
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

//HOOKS
import useLinkTo from '../../Hooks/useLinkTo'
import useError from '../../Hooks/useError'

const useStyles = makeStyles(theme => ({
  primaryBox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '& .MuiTypography-subtitle1': {
      color: 'rgb(226 226 226 / 76%)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 7px) scale(0.75)',
    },
    '& .MuiInputLabel-root': {
      left: theme.spacing(2),
    },
    '& MuiInput-root.MuiInput-underline': {
      paddingLeft: theme.spacing(2),
    },
    '& #cta-buscar-envio': {
      paddingLeft: theme.spacing(2),
    },
  },
}))

export default function SearchCta(props) {
  const { primaryBox } = useStyles()
  const inputRef = useRef()
  const linkTo = useLinkTo()
  const errorHandler = useError()

  // CTA SEARCH GUIA
  const searchGuia = e => {
    e.preventDefault()
    const value = parseInt(inputRef.current.value)
    if (isNaN(value)) {
      errorHandler({ message: 'El número envío no es válido' })
    } else {
      linkTo(`/seguimiento/${value}`)
    }
  }

  return (
    <Box className={primaryBox} width={1} py={{ xs: 10, sm: 20 }} {...props}>
      <Container maxWidth="md">
        <Typography
          variant="h2"
          style={{
            fontWeight: 700,
          }}
          align="center"
          gutterBottom
        >
          ¿Estás buscando un envío?
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Sabemos lo que es estar ansioso por recibir tu producto. Ingresá tu
          numero de envío en el formulario y conocé el estado.
        </Typography>
        <Box
          component="form"
          onSubmit={searchGuia}
          width={1}
          mt={4}
          display="flex"
          justifyContent="center"
        >
          <Paper elevation={0} style={{ overflow: 'hidden' }}>
            <TextField
              id="cta-buscar-envio"
              color="secondary"
              label="Buscar envio"
              type="tel"
              inputRef={inputRef}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="start"
                    name="Boton buscar envio"
                    type="submit"
                    style={{
                      bottom: 7,
                      right: 4,
                    }}
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </Paper>
        </Box>
      </Container>
    </Box>
  )
}
