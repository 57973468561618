import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

const ip = process.env.REACT_APP_IP_LOCAL || 'localhost'
const host = process.env.REACT_APP_TESTING
  ? 'http://clicpaq.com:8500'
  : process.env.NODE_ENV === 'production'
  ? `https://api.clicpaq.com`
  : `https://api.clicpaq.com`

//Conecto apollo link con el host
const httpLink = createHttpLink({
  uri: `${host}/graphql`,
})

//Meto el token en el header
var authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  var token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  }
})

var client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default client
