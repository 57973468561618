import React, { useRef } from 'react'
import styled from 'styled-components'
import Trigger from 'react-visibility-sensor'

// VIDEOS
import clpMp4 from '../../resources/videos/clp-1200.mp4'
import clpWebm from '../../resources/videos/clp-1200.webm'
import videoposter from '../../resources/images/webp/videoposter.webp'

// LOGOS CLIENTES
import { ReactComponent as Bedtime } from '../../resources/images/logos-clientes/SVG/bedtime.svg'
import { ReactComponent as Belmo } from '../../resources/images/logos-clientes/SVG/belmo.svg'
import { ReactComponent as BurgerKing } from '../../resources/images/logos-clientes/SVG/burger-king.svg'
import { ReactComponent as Cummins } from '../../resources/images/logos-clientes/SVG/cummins.svg'
import { ReactComponent as Fresenius } from '../../resources/images/logos-clientes/SVG/fresenius.svg'
import { ReactComponent as Infinit } from '../../resources/images/logos-clientes/SVG/infinit.svg'
import { ReactComponent as Ips } from '../../resources/images/logos-clientes/SVG/ips.svg'
import { ReactComponent as JohnDeere } from '../../resources/images/logos-clientes/SVG/john-deere.svg'
import { ReactComponent as Kohinoor } from '../../resources/images/logos-clientes/SVG/kohinoor.svg'
import { ReactComponent as Rotoplas } from '../../resources/images/logos-clientes/SVG/rotoplas.svg'
import { ReactComponent as Sealy } from '../../resources/images/logos-clientes/SVG/sealy.svg'
import { ReactComponent as Simmons } from '../../resources/images/logos-clientes/SVG/simmons.svg'
import { ReactComponent as Skf } from '../../resources/images/logos-clientes/SVG/skf.svg'
import { ReactComponent as SommierCenter } from '../../resources/images/logos-clientes/SVG/sommiercenter.svg'
import { ReactComponent as StearnsFoster } from '../../resources/images/logos-clientes/SVG/stearns-foster.svg'
import { ReactComponent as Tempur } from '../../resources/images/logos-clientes/SVG/tempur.svg'
import { ReactComponent as Tromen } from '../../resources/images/logos-clientes/SVG/tromen.svg'
import { ReactComponent as Molinos } from '../../resources/images/logos-clientes/SVG/molinos.svg'
import { ReactComponent as Ledesma } from '../../resources/images/logos-clientes/SVG/ledesma.svg'
import { ReactComponent as Arbell } from '../../resources/images/logos-clientes/SVG/arbell.svg'
import serviciosViales from '../../resources/images/logos-clientes/servicios-viales.png'
import clearPetroleum from '../../resources/images/logos-clientes/clear-petroleum.png'
import palmero from '../../resources/images/logos-clientes/palmero.png'
import guadal from '../../resources/images/logos-clientes/editorial-guadal.png'

// CUSTOM Components
import ClientSlides from './ClientsSlides'
import bp from '../../shared/CssBP'
import useLinkTo from '../../Hooks/useLinkTo'

// MATERIAL
import { Typography, Box, Button } from '@material-ui/core'

const HeroSection = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: 56px auto 100px;
  grid-template-areas:
    '.'
    'video'
    'clients';
  ${bp.ml} {
    & {
      height: calc(150vh);
      grid-template-rows: 50px auto 70vh;
    }
  } ;
`

const VideoSection = styled.div`
  position: relative;
  grid-area: video;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px;
  overflow: hidden;
  z-index: 2;
  & * {
    position: relative;
  }

  ${bp.t} {
    & h1[type='hero'] {
      font-size: 37px;
      line-height: 40px;
    }
    & h1[type='h5'] {
      margin-top: 20px;
      font-size: 16px;
      line-height: 20px;
    }
  }
`
const VideoBackground = styled.video`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
`

const BigContainer = styled.div`
  width: calc(100% - 40px);
  max-width: 1200px;
  margin: 0 auto;
`

const Clients = styled(BigContainer)`
  grid-area: clients;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(6, 1fr);
  pointer-events: none;
  ${bp.ml} {
    & {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
  }
`

export default ({ scrollTo }) => {
  //HOOKS
  const linkTo = useLinkTo()

  //VIDEO CONTROLS
  const videoRef = useRef(null)
  const videoAutoplay = isVisible => {
    if (isVisible) {
      if (videoRef.current.paused) {
        videoRef.current.play().catch(() => null)
      }
    } else {
      if (!videoRef.current.paused) {
        setTimeout(() => {
          videoRef.current.pause()
        }, 300)
      }
    }
  }

  const seguimientoClick = e => {
    e.preventDefault()
    linkTo('/seguimiento')
  }

  return (
    <HeroSection id="hero">
      <VideoSection>
        <Trigger
          onChange={videoAutoplay}
          partialVisibility={true}
          offset={{ top: 50 }}
        >
          <VideoBackground
            playsInline
            autoPlay
            loop
            muted
            ref={videoRef}
            poster={videoposter}
          >
            <source src={clpWebm} type="video/webm" />
            <source src={clpMp4} type="video/mp4" />
          </VideoBackground>
        </Trigger>
        <VideoOverlay />
        <Typography
          variant="h2"
          style={{
            color: 'white',
            fontWeight: 700,
          }}
          align="center"
        >
          Distribuí mejor. Más rápido. Informado.
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: 'white',
            fontWeight: 300,
          }}
          align="center"
        >
          Conocé la plataforma digital que le brinda el control al cliente en
          todo momento.
        </Typography>
        {/* BOTONERA DE HERO SECION */}
        <Box
          mt={5}
          width={1}
          mx={3}
          maxWidth={400}
          display={'flex'}
          justifyContent={'center'}
        >
          <Button
            onClick={seguimientoClick}
            variant="contained"
            color="primary"
            href="/seguimiento"
          >
            Buscar un envío
          </Button>
        </Box>
      </VideoSection>
      <Clients>
        {/* 1 */}
        <ClientSlides delay={6}>
          <SommierCenter />
          <Belmo />
          <Sealy />
          <Ledesma />
        </ClientSlides>
        {/* 2 */}
        <ClientSlides delay={8}>
          <Cummins />
          <BurgerKing />
          <Ips />
          <Kohinoor />
        </ClientSlides>
        {/* 3 */}
        <ClientSlides delay={10}>
          <JohnDeere />
          <div style={{ backgroundImage: `url(${clearPetroleum})` }} />
          <Rotoplas />
          <Molinos />
        </ClientSlides>
        {/* 4 */}
        <ClientSlides delay={15}>
          <Skf />
          <div style={{ backgroundImage: `url(${palmero})` }} />
          <Tromen />
          <div style={{ backgroundImage: `url(${serviciosViales})` }} />
        </ClientSlides>
        {/* 5 */}
        <ClientSlides delay={7}>
          <Bedtime />
          <Tempur />
          <StearnsFoster />
          <Arbell />
        </ClientSlides>
        {/* 6 */}
        <ClientSlides delay={13}>
          <Simmons />
          <Infinit />
          <Fresenius />
          <div style={{ backgroundImage: `url(${guadal})` }} />
        </ClientSlides>
      </Clients>
    </HeroSection>
  )
}
