import React, { useEffect, useCallback, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { Helmet } from 'react-helmet'
import { Box, Container, Typography, Paper, Divider } from '@material-ui/core'
import { Notifications as NotificationsIcon } from '@material-ui/icons'
import { styled } from '@material-ui/core/styles'

//COMPONENTS
import Topbar from './Topbar'
import HeroSection from './HeroSection'
import SearchCta from './SearchCta'
import Footer from './Footer'
import CustomLiveChat from '../../Components/CustomLiveChat'
import QuieroSerCliente from './QuieroSerCliente/Index'

import { getImg as realGetImg, getImgSet as realGetImgSet } from './getImg'

// CUSTOM VIDEO
import searchMp4 from '../../resources/videos/search.mp4'
import searchWebm from '../../resources/videos/search.webm'

// MOUSE PARALLAX
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const speed5 = (x, y) => `translate3d(${x / 16}px,${y / 16}px,0)`
const speed3 = (x, y) => `translate3d(${x / 9}px,${y / 9}px,0)`
const speed2 = (x, y) => `translate3d(${x / 6}px,${y / 6}px,0)`
const speed1 = (x, y) => `translate3d(${x / 3}px,${y / 3}px,0)`

const AnimatedBox = animated(Box)

const BigLineBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.only('xs')]: {
    background: 'none !important',
  },
  [theme.breakpoints.only('sm')]: {
    backgroundSize: '90% 90%',
    backgroundPosition: 'right 140px',
  },
  [theme.breakpoints.up('md')]: {
    backgroundSize: 'auto 90%',
    backgroundPosition: 'center 90px',
  },
}))

const SectionBox = styled(Box)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  userSelect: 'none',
  [theme.breakpoints.up('xs')]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    pointerEvents: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    pointerEvents: 'auto',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}))

const FloatingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  [theme.breakpoints.up('xs')]: {
    height: 'auto',
    alignItems: 'center',
  },
  [theme.breakpoints.up('md')]: {
    height: 450,
  },
}))

const ImageBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    paddingTop: '100%',
    maxWidth: 450,
    height: 'initial',
    order: 2,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  [theme.breakpoints.up('sm')]: {
    height: 450,
    paddingTop: 0,
  },
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    marginBottom: 0,
    order: 'initial',
    maxWidth: 'initial',
    width: '50%',
  },
}))

const PaperCutter = styled(animated(Paper))(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  overflow: 'hidden',
}))

const SectionTitleLeft = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    order: 1,
  },
  [theme.breakpoints.only('sm')]: {
    textAlign: 'center',
  },
  [theme.breakpoints.up('md')]: {
    width: '50%',
    order: 'initial',
    paddingRight: theme.spacing(6),
  },
}))
const SectionTitleRight = styled(SectionTitleLeft)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: 0,
  },
}))

const SectionSubtitleLeft = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    order: 3,
  },
  [theme.breakpoints.only('sm')]: {
    textAlign: 'center',
  },
  [theme.breakpoints.up('md')]: {
    width: '50%',
    order: 'initial',
    paddingRight: theme.spacing(6),
  },
}))

const SectionSubtitleRight = styled(SectionSubtitleLeft)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: 0,
  },
}))

export default () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const getImg = useCallback(
    (img, ext) => {
      if (isLoaded) {
        return realGetImg(img, ext)
      } else {
        return ''
      }
    },
    [isLoaded]
  )

  const getImgSet = useCallback(
    (img, ext) => {
      if (isLoaded) {
        return realGetImgSet(img, ext)
      } else {
        return ''
      }
    },
    [isLoaded]
  )

  useEffect(() => {
    window.addEventListener('load', () => {
      setIsLoaded(true)
    })
    setTimeout(() => {
      setIsLoaded(true)
    }, 300)
  }, [])

  const imageError = e => {
    const imgElement = e.target
    if (imgElement.src.includes('webp')) {
      imgElement.src = realGetImg(imgElement.id, 'png')
      imgElement.srcset = realGetImgSet(imgElement.id, 'png')
    }
  }

  // SCROLL TO FUNCTION
  const [, setY] = useSpring(() => ({ y: 0 }))
  const scrollTo = id => {
    let value
    if (typeof id === 'number') {
      value = id
    } else {
      const appbar = document.getElementById('appbar')
      const offset = appbar ? appbar.offsetHeight : 0
      const elementTop = document.getElementById(id).getBoundingClientRect().top
      value = window.scrollY + elementTop - offset
    }
    setY({
      y: value,
      reset: true,
      from: { y: window.scrollY },
      onFrame: props => window.scroll(0, props.y),
    })
  }

  const [props, set] = useSpring(() => ({
    mockups: [0, 0],
    buscar: [0, 0],
    excel: [0, 0],
    contenido: [0, 0],
    remitos: [0, 0],
    notificacion: [0, 0],
    config: {
      mass: 10,
      tension: 550,
      friction: 140,
    },
  }))
  const style = (section, speed) => ({
    transform: props[section].interpolate(speed),
  })

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Conocé a la empresa de logística y distribución con la plataforma digital más avanzada de la Argentina"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <CustomLiveChat/>
      <Topbar />
      <HeroSection scrollTo={scrollTo} />
      <SearchCta display={{ xs: 'block', sm: 'none' }} />
      <SectionBox
        onMouseMove={({ clientX: x, clientY: y }) =>
          set({ mockups: calc(x, y) })
        }
        onMouseLeave={() => set({ mockups: [0, 0] })}
      >
        <Container maxWidth="md">
          <Box
            width={1}
            position="relative"
            display="flex"
            justifyContent="flex-end"
          >
            <Box width="80%" mr={{ xs: 1, sm: 3 }} zIndex={1}>
              <animated.img
                width="100%"
                style={style('mockups', speed3)}
                id="Mockup-Macbook"
                src={getImg('Mockup-Macbook', 'webp')}
                srcSet={getImgSet('Mockup-Macbook', 'webp')}
                onError={imageError}
                alt="Pantalla de Macbook Pro con la interfaz de Clicpaq Envíos"
              />
            </Box>
            <Box
              position="absolute"
              right={0}
              top={0}
              bottom={0}
              zIndex={1}
              width="18%"
              display="flex"
              alignItems="center"
            >
              <animated.img
                width="100%"
                style={style('mockups', speed1)}
                id="Mockup-Iphone"
                src={getImg('Mockup-Iphone', 'webp')}
                srcSet={getImgSet('Mockup-Iphone', 'webp')}
                onError={imageError}
                alt="Pantalla de iphone con la interfaz de Clicpaq Envíos"
              />
            </Box>
            <Box
              position="absolute"
              left={0}
              top={0}
              bottom={0}
              zIndex={0}
              width="50%"
              display="flex"
              alignItems="center"
            >
              <animated.img
                width="100%"
                id="Mockup-iPad"
                style={style('mockups', speed5)}
                src={getImg('Mockup-iPad', 'webp')}
                srcSet={getImgSet('Mockup-iPad', 'webp')}
                onError={imageError}
                alt="Pantalla de ipad con la interfaz de Clicpaq Envíos"
              />
            </Box>
          </Box>
          <Box>
            <Typography
              style={{ fontWeight: 700 }}
              variant="h3"
              align="center"
              color="primary"
              gutterBottom
            >
              Todos tus envíos en una sola plataforma.
            </Typography>
            <Typography variant="h5" align="center">
              Accedé a toda tu información donde sea que estés.
            </Typography>
          </Box>
        </Container>
      </SectionBox>
      <BigLineBox
        style={{ backgroundImage: `url(${getImg('long-line', 'webp')})` }}
      >
        {/* SECCION BUSQUEDA */}
        <Box
          display={{
            xs: 'flex',
            sm: 'none',
          }}
          py={3}
          width={1}
          justifyContent="center"
        >
          <Divider style={{ width: '50%' }} />
        </Box>
        <SectionBox
          onMouseMove={({ clientX: x, clientY: y }) =>
            set({ buscar: calc(x, y) })
          }
          onMouseLeave={() => set({ buscar: [0, 0] })}
        >
          <Container maxWidth="md" style={{ position: 'relative' }}>
            <FloatingBox>
              <ImageBox>
                <PaperCutter elevation={2} style={style('buscar', speed5)}>
                  <video
                    playsInline
                    autoPlay
                    loop
                    muted
                    style={{
                      width: '100%',
                      heigh: '100%',
                    }}
                  >
                    <source src={searchMp4} type="video/mp4" />
                    <source src={searchWebm} type="video/webm" />
                  </video>
                </PaperCutter>
              </ImageBox>
              <SectionTitleRight variant="h4" color="primary" gutterBottom>
                Buscá tus envíos como siempre deberías haberlo hecho.
              </SectionTitleRight>
              <SectionSubtitleRight variant="body1">
                Nombre de destinatario, número de envío, localidad de destino o
                número de remito.
                <br />
                Ningún dato es un problema para nosotros.
                <br />
                (La velocidad de respuesta es real)
              </SectionSubtitleRight>
            </FloatingBox>
          </Container>
        </SectionBox>
        {/* SECCION BUSQUEDA */}
        <Box
          display={{
            xs: 'flex',
            sm: 'none',
          }}
          py={3}
          width={1}
          justifyContent="center"
        >
          <Divider style={{ width: '50%' }} />
        </Box>
        {/* SECCION EXCEL */}
        <SectionBox
          onMouseMove={({ clientX: x, clientY: y }) =>
            set({ excel: calc(x, y) })
          }
          onMouseLeave={() => set({ excel: [0, 0] })}
        >
          <Container maxWidth="md">
            <FloatingBox>
              <SectionTitleLeft variant="h4" color="primary" gutterBottom>
                Exportá tus datos a Excel. Sólo indicanos las fechas.
              </SectionTitleLeft>
              <SectionSubtitleLeft variant="body1">
                Todos los datos del envío se encuentran a disposición. Columnas
                dinámicas, filtrado avanzado y más.
              </SectionSubtitleLeft>
              <ImageBox>
                <PaperCutter elevation={2} style={style('excel', speed5)}>
                  <img
                    id="calendar-bg"
                    src={getImg('calendar-bg', 'webp')}
                    srcSet={getImgSet('calendar-bg', 'webp')}
                    alt="Imagen de fondo con muestra de la interfaz del software"
                    onError={imageError}
                    style={{ width: '100%' }}
                  />
                </PaperCutter>
                <AnimatedBox
                  position="absolute"
                  top={0}
                  right={0}
                  bottom="9%"
                  width="75%"
                  display="flex"
                  alignItems="center"
                  style={style('excel', speed2)}
                >
                  <img
                    width="100%"
                    id="floating-calendar"
                    src={getImg('floating-calendar', 'webp')}
                    srcSet={getImgSet('floating-calendar', 'webp')}
                    onError={imageError}
                    alt="Vista previa del exportador de datos basado en calendario"
                  />
                </AnimatedBox>
              </ImageBox>
            </FloatingBox>
          </Container>
        </SectionBox>
        {/* SECCION EXCEL */}
        <Box
          display={{
            xs: 'flex',
            sm: 'none',
          }}
          py={3}
          width={1}
          justifyContent="center"
        >
          <Divider style={{ width: '50%' }} />
        </Box>
        {/* SECCION CONTENIDO */}
        <SectionBox
          onMouseMove={({ clientX: x, clientY: y }) =>
            set({ contenido: calc(x, y) })
          }
          onMouseLeave={() => set({ contenido: [0, 0] })}
        >
          <Container maxWidth="md">
            <FloatingBox>
              <ImageBox>
                <PaperCutter elevation={2} style={style('contenido', speed5)}>
                  <img
                    id="contenido-bg"
                    src={getImg('contenido-bg', 'webp')}
                    srcSet={getImgSet('contenido-bg', 'webp')}
                    alt="Imagen de fondo con muestra de la interfaz del software"
                    onError={imageError}
                    style={{ width: '100%' }}
                  />
                </PaperCutter>
                <AnimatedBox
                  position="absolute"
                  left={0}
                  bottom="-7%"
                  width={{ xs: 1, sm: '110%' }}
                  style={style('contenido', speed2)}
                >
                  <img
                    width="100%"
                    id="floating-contenido"
                    src={getImg('floating-contenido', 'webp')}
                    srcSet={getImgSet('floating-contenido', 'webp')}
                    onError={imageError}
                    alt="Vista previa del contenido de un pedido"
                  />
                </AnimatedBox>
              </ImageBox>
              <SectionTitleRight variant="h4" color="primary" gutterBottom>
                Conocé el contenido de tus envíos donde sea que estén.
              </SectionTitleRight>
              <SectionSubtitleRight variant="body1">
                Almacená tus productos en nuestros depósitos y disfrutá de la
                ventajas de sólo tener que hacer clic para enviar.
              </SectionSubtitleRight>
            </FloatingBox>
          </Container>
        </SectionBox>
        {/* SECCION CONTENIDO */}
        <Box
          display={{
            xs: 'flex',
            sm: 'none',
          }}
          py={3}
          width={1}
          justifyContent="center"
        >
          <Divider style={{ width: '50%' }} />
        </Box>
        {/* SECCION REMITOS */}
        <SectionBox
          onMouseMove={({ clientX: x, clientY: y }) =>
            set({ remitos: calc(x, y) })
          }
          onMouseLeave={() => set({ remitos: [0, 0] })}
        >
          <Container maxWidth="md">
            <FloatingBox>
              <SectionTitleLeft variant="h4" color="primary" gutterBottom>
                Remitos conformados digitales en el día de la entrega.
              </SectionTitleLeft>
              <SectionSubtitleLeft variant="body1">
                Olvidate de buscar el remito físico para confirmar una entrega.
                Aprovechá nuestro buscador inteligente, encontrá tu envío y
                apretá descargar
              </SectionSubtitleLeft>
              <ImageBox>
                <PaperCutter elevation={2} style={style('remitos', speed5)}>
                  <img
                    id="remito-bg"
                    src={getImg('remito-bg', 'webp')}
                    srcSet={getImgSet('remito-bg', 'webp')}
                    onError={imageError}
                    alt="Imagen de fondo con muestra de la interfaz del software"
                    style={{ width: '100%' }}
                  />
                </PaperCutter>
                <AnimatedBox
                  position="absolute"
                  right={0}
                  bottom="-9%"
                  width={{ xs: 1, sm: '110%' }}
                  display="flex"
                  alignItems="center"
                  style={style('remitos', speed2)}
                >
                  <img
                    width="100%"
                    id="floating-remito"
                    src={getImg('floating-remito', 'webp')}
                    srcSet={getImgSet('floating-remito', 'webp')}
                    onError={imageError}
                    alt="Vista previa un remito conformado"
                  />
                </AnimatedBox>
              </ImageBox>
            </FloatingBox>
          </Container>
        </SectionBox>
        {/* SECCION REMITOS */}
        <Box
          display={{
            xs: 'flex',
            sm: 'none',
          }}
          py={3}
          width={1}
          justifyContent="center"
        >
          <Divider style={{ width: '50%' }} />
        </Box>
        {/* SECCION NOTIFICACION */}
        <SectionBox
          onMouseMove={({ clientX: x, clientY: y }) =>
            set({ notificacion: calc(x, y) })
          }
          onMouseLeave={() => set({ notificacion: [0, 0] })}
        >
          <Container maxWidth="md">
            <FloatingBox>
              <ImageBox>
                <PaperCutter
                  elevation={2}
                  style={style('notificacion', speed5)}
                >
                  <img
                    id="notification-bg"
                    src={getImg('notification-bg', 'webp')}
                    srcSet={getImgSet('notification-bg', 'webp')}
                    onError={imageError}
                    alt="Imagen de fondo con muestra de la interfaz del software"
                    style={{ width: '100%' }}
                  />
                </PaperCutter>
              </ImageBox>
              <SectionTitleRight variant="h4" color="primary" gutterBottom>
                Activá la notificación y recibí en tu buzón todos los cambios
                del envío.
              </SectionTitleRight>
              <SectionSubtitleRight variant="body1">
                Se acabaron las mañanas de apretar recargar una y otra vez.
                Buscá el envío que necesites, hacé clic en la
                <NotificationsIcon color="disabled" />y relajate. Nosotros nos
                ocupamos del resto.
              </SectionSubtitleRight>
            </FloatingBox>
          </Container>
        </SectionBox>
        {/* SECCION NOTIFICACION */}
      </BigLineBox>
      <QuieroSerCliente />
      <SearchCta display={{ xs: 'none', sm: 'block' }} />
      <Footer scrollTo={scrollTo} />
    </>
  )
}
