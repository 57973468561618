// FUNCIONALES
import React, { useState, useMemo, Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import ReactGA from 'react-ga'
import '@gouch/to-title-case'

// COMPONENTS
import Landing from '../Modules/Landing/Index'

// HOOKS
import { useLocation } from 'react-router-dom'

// MATERIAL
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import AlertContext from '../Contexts/AlertContext'

import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core/styles'
import { Box, CircularProgress } from '@material-ui/core'

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8F1E2E',
    },
    secondary: {
      main: '#47030D',
    },
  },
  typography: {
    fontFamily: [
      'proxima-nova',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      fontWeight: 'bold',
    },
  },
})
theme = responsiveFontSizes(theme)

//LAZY LOADING
//PUBLIC
const Login = React.lazy(() => import('../Authenticate/Login'))
const ActivateUser = React.lazy(() =>
  import('../Modules/Usuario/Public/ActivateUser')
)
const RecoverPassword = React.lazy(() =>
  import('../Modules/Usuario/Public/RecoverPassword')
)
const ReactivateUser = React.lazy(() =>
  import('../Modules/Usuario/Public/ReactivateUser')
)
const Seguimiento = React.lazy(() =>
  import('../Modules/Seguimiento/Seguimiento')
)
const SeguimientoIndex = React.lazy(() => import('../Modules/Seguimiento'))
const Error = React.lazy(() => import('../Components/Error'))
// ROUTES
const App = React.lazy(() => import('./App'))

const HandleRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    //INITIALIZE GOOGLE ANALYTICS
    ReactGA.initialize('UA-99786699-2')
  }, [])

  useEffect(() => {
    //INFORM CHANGE OF ROUTES
    ReactGA.ga('set', 'page', location.pathname + location.search)
    ReactGA.ga('send', 'pageview')
  }, [location])
  return <></>
}

const Main = () => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') return null
    setOpen(false)
  }

  const alertData = useMemo(
    () => ({
      setOpen,
      setMessage,
      setSeverity,
    }),
    [setOpen, setMessage, setSeverity]
  )

  return (
    <AlertContext.Provider value={alertData}>
      <ThemeProvider theme={theme}>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={severity}
            closeText="Cerrar"
          >
            {message}
          </Alert>
        </Snackbar>
        <Router>
          <HandleRoutes />
          <Switch>
            {/* RUTAS PÚBLICAS */}
            <Route exact path="/">
              <Landing />
            </Route>

            <Suspense
              fallback={
                <Route>
                  <Box pt={6} width={1} display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                </Route>
              }
            >
              <Switch>
                <Redirect
                  from="/entregar/:guia"
                  to="/app/envios/entregar/:guia"
                />
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/activate/:nanoId">
                  <ActivateUser />
                </Route>
                <Route exact path="/recovery/:nanoId">
                  <RecoverPassword />
                </Route>
                <Route exact path="/reactivate/:nanoId">
                  <ReactivateUser />
                </Route>
                <Route path="/seguimiento/:id">
                  <Seguimiento />
                </Route>
                <Route path="/seguimiento" exact>
                  <SeguimientoIndex />
                </Route>

                {/* RUTAS EN APP */}
                <Router path="/app/">
                  <App />
                </Router>

                {/* 404 */}
                <Route>
                  <Error />
                </Route>
              </Switch>
            </Suspense>
          </Switch>
        </Router>
      </ThemeProvider>
    </AlertContext.Provider>
  )
}

export default Main
