import client from './index'
import errorBuilder from './errorBuilder'

export default (MUTATION, args, times) => new Promise((res, rej) => {
    if(MUTATION.kind === undefined) rej({message: "Debe proveer un objecto de GQL en el primer argumento"})
    if(args && typeof args !== 'object') rej({message: "Debe proveer un objecto con los argumentos"})
    
    const mutationName = MUTATION.definitions[0].selectionSet.selections[0].name.value
    let intentos = 0
    const config = {
        mutation: MUTATION,
        variables: args,
        errorPolicy: "all",
        fetchPolicy:"no-cache"
    }
    const mutate = () => {
        const timeout = intentos * 500
        setTimeout(async () => {
            try{
                const {data, errors} = await client.mutate(config)
                const trueData = data[mutationName]
                if(errors) return rej(errorBuilder(errors))
                return res(trueData)
            } catch(err){
                if(times && intentos <= times){
                    intentos++
                    mutate() 
                } else{
                    if(process.env.NODE_ENV === 'DEVELOPMENT') console.error(err)
                    rej({
                        type: err.name,
                        message: err.message,
                        scope: mutationName
                    })
                }
            }
        }, timeout > 30000 ? 30000 : timeout)
    }
    mutate()
})